
import { Component, Vue } from 'vue-property-decorator'
import { MapBindDeviceList } from '@/types/intelligentControl'
import { FileInfo } from '@/types/uploadFile'
import UploadFile from '@/components/uploadFile/Index.vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import LocationImg from '@/assets/img/location.png'
import { ElForm } from 'element-ui/types/form'
import { drawPolygon } from '@/utils/formatData'

type Event = {
  lnglat: {
    lat: number;
    lng: number;
    getLng: () => number;
    getLat: () => number;
  };
}

@Component({
  name: 'BindDevice',
  components: { UploadFile }
})

export default class BindDeviceList extends Vue {
  private searchInfo: { deviceName: string; controlDeviceNumber: string } = { deviceName: '', controlDeviceNumber: '' }
  private tableData: { loading: boolean; data: MapBindDeviceList[] } = {
    loading: false,
    data: []
  }

  // 遥控串号列表
  private listData = []
  // 未绑定路数
  private routeNotList: Array<{ controlRoute: string }>= []
  // 保存之前的路数
  private editSaveData = { controlDeviceNumber: '', controlRoute: '' }
  private longitude = 0
  private latitude = 0
  private page = 1
  private size = 10
  private total = 0
  private isShowDialog = false
  private isEdit = false
  private deviceInfo: MapBindDeviceList ={
    // 需要绑定的设备名称
    deviceName: '',
    // 控制器设备ID
    controlDeviceId: '',
    controlDeviceNumber: '',
    // 控制器路数
    controlRoute: '',
    longitude: '',
    latitude: '',
    fileImg: []
  }

  private marker: AMap.Marker | any = null
  private map: AMap.Map | any = null
  private rules = {
    deviceName: [{ required: true, message: '请输入绑定设备名称', trigger: 'change' }],
    controlDeviceNumber: [{ required: true, message: '请选择所属遥控串号', trigger: 'change' }],
    controlRoute: [{ required: true, message: '请选择路数', trigger: 'change' }],
    longitude: [{ required: true, message: '请选择绑定位置', trigger: 'change' }]
  }

  get deviceInfoBak () {
    return {
      // 需要绑定的设备名称
      deviceName: '',
      // 控制器设备ID
      controlDeviceId: '',
      controlDeviceNumber: '',
      // 控制器路数
      controlRoute: '',
      longitude: '',
      latitude: '',
      fileImg: []
    }
  }

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  created () {
    this.getData()
  }

  destroyed () {
    if (this.map) {
      this.removeMarker()
      this.map.destroy()
    }
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.intelligentControl.selectControlDeviceByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onAdd () {
    this.isEdit = false
    this.isShowDialog = true
    this.loadMap()
    this.getListData()
  }

  // 编辑工程
  onEdit (row: MapBindDeviceList) {
    this.$axios.get(this.$apis.intelligentControl.selectControlDeviceById, { id: row }).then(res => {
      this.deviceInfo = JSON.parse(JSON.stringify(res))
      this.deviceInfo.fileImg = []
      if (res.fileImg) {
        this.deviceInfo.fileImg.push(res.fileImg)
      }
      this.isShowDialog = true
      this.isEdit = true
      this.loadMap(+this.deviceInfo.longitude, +this.deviceInfo.latitude)
      this.getListData()
      this.searchRoute(this.deviceInfo.controlDeviceId, true)
      this.deviceInfo.controlDeviceNumber = this.deviceInfo.controlDeviceId || ''
      this.editSaveData = { controlRoute: this.deviceInfo.controlRoute, controlDeviceNumber: this.deviceInfo.controlDeviceNumber }
    })
  }

  // 删除工程
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.intelligentControl.deleteDataControlDevice, { id: id }).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.onSearch()
      })
    })
  }

  // 查询遥控设备
  getListData () {
    this.$axios.get(this.$apis.intelligentControl.selectControl, {
      projectId: this.projectId
    }).then(res => {
      this.listData = res.devices || []
    })
  }

  // 查询路数
  searchRoute (val: string, isInit: boolean) {
    this.$axios.get(this.$apis.intelligentControl.selectNotDeviceByList, {
      controlDeviceId: val
    }).then(res => {
      this.routeNotList = res || []
      if (!isInit) {
        this.deviceInfo.controlRoute = ''
      }
      if (this.isEdit && val === this.editSaveData.controlDeviceNumber) {
        this.routeNotList.push({ controlRoute: this.editSaveData.controlRoute })
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.deviceInfo.fileImg.push(file)
  }

  imgRemove (index: number) {
    this.deviceInfo.fileImg.splice(index, 1)
  }

  loadMap (lng?: number, lat?: number) {
    AMapLoader.load({
      key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then(() => {
      const map = new AMap.Map('container', {
        zoom: 15
      })
      map.on('click', (e: Event) => {
        this.setLngLat(e.lnglat.getLng(), e.lnglat.getLat())
        this.addMarker(this.longitude, this.latitude)
      })
      this.map = map
      if (lng && lat) {
        this.addMarker(lng, lat)
      }
      this.loadLocationList()
    }).catch(e => {
      this.$message({ type: 'error', message: '地图加载失败：' + JSON.stringify(e) })
    })
  }

  // 画电子围栏
  loadLocationList () {
    this.$axios.get(this.$apis.project.projectLocationList, {
      objectId: this.projectId
    }).then((res) => {
      // 画电子围栏
      drawPolygon(this.map, res.list, null, (e: Event) => {
        this.setLngLat(e.lnglat.getLng(), e.lnglat.getLat())
        this.addMarker(this.longitude, this.latitude)
      })
    })
  }

  setLngLat (lng: number, lat: number) {
    this.longitude = lng
    this.latitude = lat
    this.deviceInfo.longitude = this.longitude.toString()
    this.deviceInfo.latitude = this.latitude.toString()
  }

  addMarker (lng: number, lat: number) {
    this.removeMarker()
    this.marker = new AMap.Marker({
      map: this.map,
      position: new AMap.LngLat(lng, lat),
      offset: new AMap.Pixel(-20, -40),
      icon: LocationImg,
      draggable: true
    })
    this.marker.on('dragstart', (e: Event) => {
      this.marker.setPosition(new AMap.LngLat(e.lnglat.lng, e.lnglat.lat))
    })
    this.marker.on('dragend', (e: Event) => {
      this.setLngLat(e.lnglat.getLng(), e.lnglat.getLat())
    })
    this.map.add(this.marker)
  }

  removeMarker () {
    if (this.marker) {
      this.map.remove(this.marker)
    }
  }

  onSubmit () {
    (this.$refs.DigForm as ElForm).validate((valid) => {
      if (valid) {
        const info = JSON.parse(JSON.stringify(this.deviceInfo))
        info.fileImg = this.deviceInfo.fileImg[0]
        info.controlDeviceId = this.deviceInfo.controlDeviceNumber || this.deviceInfo.controlDeviceId
        delete info.controlDeviceNumber
        const url = this.isEdit ? this.$apis.intelligentControl.updateControlDevice : this.$apis.intelligentControl.insertControlDevice
        this.$axios.post(url, info).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.getData()
        })
      }
    })
  }

  // 关闭
  closeDialog () {
    (this.$refs.DigForm as ElForm).resetFields()
    this.isShowDialog = false
    this.deviceInfo = this.deviceInfoBak
  }
}
